<template>
  <div class="container bg-white p-10">
    <div class="header">
      账号密码
    </div>
    <div class="text">
      验证账号
    </div>
    <div v-if="isShow">
      <div class="form">
        <div class="texts">
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          用户名：<span>{{ userInfo.username.slice(0, 3) }}****{{ userInfo.username.slice(7, 11) }}</span>
        </div>
        <div class="input">
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span>*</span>
          手机验证码：<input v-model="code" placeholder="请输入验证码" type="text">
          <el-button :disabled="times == 60?false:true" :type="times == 60 ?'primary':'info'" style="margin-left:15px;" @click="getCode">
            {{ times == 60 ? '发送验证码' : '重新获取' + times +'秒' }}
          </el-button>
        </div>
      </div>
      <div class="next" @click="handleNext">
        下一步
      </div>
    </div>

    <div v-else>
      <div class="form">
        <div class="input">
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;新密码：
          <input v-model="pwd" placeholder="请输入新密码" type="password">
        </div>
        <br>
        <div class="input1">
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span>重复新密码：</span>
          <input v-model="rePwd" placeholder="请重复新密码" type="password">
        </div>
      </div>
      <div class="next" @click="handleSubmit">
        确认修改
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { forgetPasswordEdit, forgetPasswordSendCode } from '@/api/Login'
export default {
    name: 'Edit',
    data() {
        return {
            uuid: '',
            index: 0,
            isShow: true,
            pwd: '',
            rePwd: '',
            code: '',
            text: '发送验证码',
            timer:null,
            times:60
        }
    },
    computed: {
        ...mapGetters(['userInfo'])
    },
    created() {
        this.index = this.$route.params.id
    },
    methods: {
        handleNext() {
            if (this.code == '') {
                this.$message.error('验证码为空')
            } else {
                this.isShow = false
            }
        },
        LogOut() {
            this.$store.dispatch('FedLogOut').then(() => {
              this.$router.push({ path: '/login' })
            })
        },
        handleSubmit() {
            let that = this
            if (this.pwd !== this.rePwd) {
                this.$message.error('两次密码不一致')
            } else if(this.pwd.length <6 || this.rePwd.length <6){
                this.$message.error('密码最小6位数')
            }else{
                let data = {
                    uuid: this.uuid,
                    code: this.code,
                    newPassword: this.pwd,
                    phone: this.userInfo.username
                }
                forgetPasswordEdit(data).then(res => {
                  this.$confirm('密码修改成功，请重新登录', '提示', {
                    confirmButtonText: '确定',
                    showCancelButton: false,
                    showClose: false,
                    type: 'success'
                  }).then(() => {
                    that.LogOut()
                  })
                }).catch(e => e)
            }
        },
        getCode() {
            if (this.userInfo.username !== '') {
                this.times = 60 // 倒计时时间
                this.timer = setInterval(() => {
                    this.times--
                    if (this.times == 0) {
                        clearInterval(this.timer)
                        this.times = 60
                    }
                }, 1000)
                forgetPasswordSendCode(this.userInfo.username).then((res) => {
                    this.uuid = res.data
                }).catch(() => {
                    this.code.status = 2
                    this.times = 60
                    clearInterval(this.timer)
                })
            }
        },
    }
}

</script>
<style lang="scss" scoped>
.container {
    position: relative;
    width: 100%;
    height: 550px;
    background-color: #fff;
    padding: 40px 250px 0 250px;

    .header {
        text-align: left;
        padding-left: 20px;
        height: 40px;
        line-height: 40px;
        background: #0962ea;
        box-shadow: 0 3px 20px rgba(9, 98, 234, 0.16);
        font-family: "PingFang SC Bold";
        font-weight: 700;
        font-size: 14px;
        color: #fff;
    }

    .text {
        font-family: "PingFang SC Bold";
        font-weight: 700;
        font-size: 12;
        color: #000;
        margin: 30px 0 0 20px;
    }

    .form {
        margin: auto;
        width: 400px;
        // border: 1px solid red;

        .texts {
            margin: 10px 0;
            width: 440px;
            padding-left: 35px;
            // border: 1px solid red;

            span {
                font-family: "PingFang SC";
                font-weight: 400;
                font-size: 12px;
                text-align: left;
                color: #00c48c;
            }
        }

        .input {
            margin: 10px 0;
            width: 540px;
            // border: 1px solid red;
            display: flex;
            align-items: center;

            input {
                border: none;
                outline: none;
                width: 223px;
                height: 40px;
                padding-left: 5px;
                background-color: #f6f6f6;
            }

            .btn {
                width: 94px;
                height: 34px;
                border-radius: 4px;
                background: #0962ea;
                line-height: 34px;
                text-align: center;
                color: #fff;
                margin-left: 20px;
                cursor: pointer;
            }

            span {
                color: #FF0000;
            }
        }

        .input1 {
            display: flex;
            align-items: center;


            input {
                border: none;
                outline: none;
                width: 223px;
                height: 40px;
                padding-left: 5px;
                background-color: #f6f6f6;
            }

            span {
                display: inline-block;
            }
        }
    }

    .next {
        margin: 120px auto;
        width: 218px;
        height: 42px;
        border-radius: 7px;
        background: #bb3c72;
        text-align: center;
        line-height: 42px;
        color: #fff;
        cursor: pointer;
    }
}
</style>
